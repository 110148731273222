export default {
  path: "/dashboard/user",
  i18nPath: "pages.users",
  schema: {
    mobileNo: {
      type: String,
      required: true,
      sortable: false,
      persianNumber: true,
      filterOperator: "lk"
    },
    firstName: {
      type: String,
      order: 2,
      filterOperator: "lk"
    },
    lastName: {
      type: String,
      order: 3,
      filterOperator: "lk"
    },
    role: {
      type: String,
      required: true,
      default: "client",
      i18nPath: "roles",
      enum: ["admin", "operator", "client"],
      addable: false,
      inputGrid: {
        cols: 12,
        md: 12
      }
    },
    createAt: {
      type: Date,
      editable: false,
      addable: false,
      showInJalaali: true
    },
    lastUpdate: {
      type: Date,
      editable: false,
      addable: false,
      showInJalaali: true
    },
    lastLogin: {
      type: Date,
      editable: false,
      addable: false,
      showInJalaali: true
    },
    active: {
      type: Boolean,
      required: true,
      default: true,
      order: 1,
      name: "status",
      icons: ["mdi-check-circle", "mdi-close-circle"],
      inputGrid: {
        cols: 12,
        md: 12
      }
    },
    organization: {
      type: String,
      default: null,
      order: 4
    },
    eventGroups: {
      type: Array,
      ref: "eventGroup",
      multiple: true,
      itemValue: "_id",
      itemText: "name",
      descriptionText: "description",
      searchField: ["name"],
      joinChar: " | ",
      minCharSearch: 2,
      smallChips: true,
      inputGrid: {
        cols: 12,
        md: 12
      }
    }
  }
};
