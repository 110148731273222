<template>
  <div>
    <v-text-field
      :value="dateText"
      outlined
      :label="label"
      :disabled="fieldModel.disabled"
      :rules="
        fieldModel.required
          ? $globalRules.required(
              $vuetify.lang.t(`$vuetify.validation.required`)
            )
          : []
      "
      :hide-details="fieldModel.hideDetails || false"
      :required="fieldModel.required"
      :dense="fieldModel.dense || true"
      :id="`date-picker-${fieldModel.value}${idSuffix}`"
      @input="onChange"
      @focus="a => a.target.click()"
      clearable
      :locale="fieldModel.locale"
      :localeConfig="fieldModel.localeConfig"
    ></v-text-field>
    <date-picker
      :value="date"
      :format="fieldModel.format || 'jYYYY/jMM/jDD'"
      @input="onInput"
      @change="onChange"
      :convertNumbers="fieldModel.convertNumbers || true"
      :element="`date-picker-${fieldModel.value}${idSuffix}`"
      :min="formatedMin"
      :max="formatedMax"
      :view="fieldModel.view"
      :locale="fieldModel.locale"
      append-to="body"
      :localeConfig="fieldModel.localeConfig"
    />
  </div>
</template>

<script>
import jMoment from "moment-jalaali";
import { persianNumber } from "@/plugins/filters";
export default {
  props: ["value", "fieldModel", "label", "idSuffix", "min", "max"],
  data() {
    return {
      date: undefined,
      dateText: ""
    };
  },
  computed: {
    formatedMin() {
      return this.min && this.formatDate(this.min);
    },
    formatedMax() {
      return this.max && this.formatDate(this.max);
    }
  },
  methods: {
    onInput(val) {
      this.date = val;
      this.$emit("input", this.date);
    },
    onChange(val) {
      this.$emit("change", val);
    },
    formatDate(val) {
      jMoment.loadPersian({
        usePersianDigits: false
      });

      return (
        val && jMoment(val).format(this.fieldModel.format || "jYYYY/jMM/jDD")
      );
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.date = this.formatDate(val);
      }
    },
    date: {
      immediate: true,
      handler(val) {
        this.dateText = val && persianNumber(val);
      }
    }
  }
};
</script>
