export default {
  path: "/dashboard/event",
  i18nPath: "pages.events",
  editable: false,
  addable: false,
  schema: {
    title: {
      type: String,
      required: true,
      filterOperator: "lk"
    },
    start: {
      type: Date,
      required: true,
      showInJalaali: true,
      format: "jYYYY/jMM/jDD"
    },
    end: {
      type: Date,
      showInJalaali: true,
      format: "jYYYY/jMM/jDD"
    },
    color: {
      type: String,
      subType: "color"
    },
    eventSource: {
      type: String,
      ref: "eventSource",
      multiple: false,
      itemValue: "_id",
      itemText: "title",
      descriptionText: "description",
      searchField: ["title"],
      minCharSearch: 2,
      noChip: true
    },
    eventGroup: {
      type: String,
      ref: "eventGroup",
      multiple: false,
      itemValue: "_id",
      itemText: "name",
      descriptionText: "description",
      searchField: ["name"],
      minCharSearch: 2,
      noChip: true
    },
    calendarType: {
      type: String,
      enum: ["Gregorian", "Jalaali"],
      i18nPath: "pages.calendar.calendarType",
      required: true,
      default: "Jalaali",
      editable: false,
      addable: false
    },
    score: {
      type: Number,
      min: -10,
      max: 10,
      required: true,
      editable: false,
      addable: false,
      persianNumber: true
    },
    area: {
      type: String,
      enum: ["local", "region", "province", "country", "global"],
      i18nPath: "pages.calendar.area",
      editable: false,
      addable: false
    },
    actors: {
      type: Array,
      ref: "actor",
      multiple: true,
      hideInBrowse: true,
      itemValue: "_id",
      itemText: "name",
      descriptionText: "description",
      searchField: ["name"],
      joinChar: " | ",
      minCharSearch: 2,
      smallChips: true,
      editable: false,
      addable: false
    },
    scopes: {
      type: Array,
      ref: "scope",
      multiple: true,
      hideInBrowse: true,
      itemValue: "_id",
      itemText: "name",
      descriptionText: "description",
      searchField: ["name"],
      joinChar: " | ",
      minCharSearch: 2,
      smallChips: true,
      editable: false,
      addable: false
    },
    isMainSubject: {
      type: Boolean,
      default: true,
      editable: false,
      icons: ["mdi-check-circle", "mdi-close-circle"],
      addable: false
    },
    description: {
      type: String,
      multiLine: true,
      editable: false,
      addable: false,
      filterOperator: "lk"
    },
    createAt: {
      type: Date,
      editable: false,
      addable: false,
      showInJalaali: true,
      hide: true
    },
    lastUpdate: {
      type: Date,
      editable: false,
      addable: false,
      showInJalaali: true,
      hide: true
    },
    active: {
      type: Boolean,
      required: true,
      default: true,
      editable: false,
      addable: false,
      order: 1,
      name: "status",
      icons: ["mdi-check-circle", "mdi-close-circle"],
      inputGrid: {
        cols: 12,
        md: 12
      }
    }
  }
};
