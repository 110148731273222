<template>
  <v-checkbox
    v-if="fieldModel.type === Boolean"
    v-model="innerValue"
    :label="label + (fieldModel.required ? '*' : '')"
    class="my-auto"
    @change="onChange"
    :hide-details="fieldModel.hideDetails || false"
    :required="fieldModel.required"
    :dense="fieldModel.dense || true"
  />
  <refField
    v-else-if="fieldModel.ref"
    v-model="innerValue"
    :fieldModel="fieldModel"
    :label="label + (fieldModel.required ? '*' : '')"
    @change="onChange"
  />
  <date
    v-else-if="fieldModel.type === Date"
    v-model="innerValue"
    :fieldModel="fieldModel"
    :label="label + (fieldModel.required ? '*' : '')"
    @change="changeDate"
  />
  <v-select
    v-else-if="fieldModel.enum"
    :items="
      fieldModel.i18nPath
        ? fieldModel.enum.map(value => ({
            text: this.$vuetify.lang.t(
              `$vuetify.${fieldModel.i18nPath}.${value}`
            ),
            value
          }))
        : fieldModel.enum
    "
    v-model="innerValue"
    :label="label + (fieldModel.required ? '*' : '')"
    @change="onChange"
    :hide-details="fieldModel.hideDetails || false"
    :required="fieldModel.required"
    :dense="fieldModel.dense || true"
    outlined
    :clearable="!fieldModel.required"
  />
  <v-textarea
    v-else-if="fieldModel.type === String && fieldModel.multiLine"
    :label="label + (fieldModel.required ? '*' : '')"
    v-model="innerValue"
    :disabled="fieldModel.disabled"
    :hide-details="fieldModel.hideDetails || false"
    :required="fieldModel.required"
    @change="onChange"
    outlined
    :dense="fieldModel.dense || true"
  />
  <color
    v-else-if="fieldModel.subType && fieldModel.subType === 'color'"
    v-model="innerValue"
    :label="label + (fieldModel.required ? '*' : '')"
    :fieldModel="fieldModel"
    @change="onChange"
  />
  <v-text-field
    v-else
    v-model="innerValue"
    outlined
    :type="fieldModel.type === Number ? 'number' : 'text'"
    :label="label + (fieldModel.required ? '*' : '')"
    :disabled="fieldModel.disabled"
    :rules="
      fieldModel.required
        ? $globalRules.required($vuetify.lang.t(`$vuetify.validation.required`))
        : []
    "
    :step="fieldModel.isDecimal == 'decimal' ? 0.01 : 1"
    :mask="
      ['date', 'time', 'datetime'].includes(fieldModel.fieldType)
        ? masks[fieldModel.fieldType]
        : undefined
    "
    :return-masked-value="
      ['date', 'time', 'datetime'].includes(fieldModel.fieldType) ? true : false
    "
    :min="fieldModel.min"
    :max="fieldModel.max"
    :hide-details="fieldModel.hideDetails || false"
    :required="fieldModel.required"
    @change="onChange"
    :dense="fieldModel.dense || true"
  />
</template>

<script>
import date from "./date";
import refField from "./refField";
import color from "./color";
export default {
  props: ["value", "fieldModel", "label", "default", "backgroundColor"],
  data() {
    return {
      innerValue: undefined,
      masks: {
        date: "####-##-##",
        time: "##:##",
        datetime: "####-##-## ##:##:##"
      }
    };
  },
  methods: {
    onChange(val) {
      this.$emit("input", val);
    },
    changeDate(value) {
      this.$emit("input", value._d);
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.innerValue = val ?? this.$props.default;
      }
    }
  },
  created() {
    !this.$props.value &&
      this.$props.default &&
      this.$emit("input", this.$props.default);
  },
  components: {
    date,
    refField,
    color
  }
};
</script>
