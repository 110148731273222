import actor from "./actor";
import eventGroup from "./eventGroup";
import scope from "./scope";
import user from "./user";
import event from "./event";
import eventSource from "./eventSource";

export default {
  event,
  eventSource,
  actor,
  eventGroup,
  scope,
  user
};
