<template>
  <v-row>
    <v-col>
      <inputDate
        :value="date[0]"
        :label="`${label} (${$vuetify.lang.t(`$vuetify.date.from`)})`"
        :fieldModel="{ ...fieldModel, required: false }"
        @input="v => onInput(v, 0)"
        @change="v => onChange(v, 0)"
        idSuffix="0"
      />
    </v-col>
    <v-col>
      <inputDate
        :value="date[1]"
        :label="`${label} (${$vuetify.lang.t(`$vuetify.date.to`)})`"
        :fieldModel="{ ...fieldModel, required: false }"
        @input="v => onInput(v, 1)"
        @change="v => onChange(v, 1)"
        idSuffix="1"
      />
    </v-col>
  </v-row>
</template>

<script>
import inputDate from "../inputFields/date";
export default {
  props: ["value", "fieldModel", "label", "default"],
  data() {
    return {
      date: [undefined, undefined],
      innerValue: [undefined, undefined]
    };
  },
  components: {
    inputDate
  },
  methods: {
    onInput(value, index) {
      this.date[index] = value;
      this.$emit("input", this.date);
    },
    onChange(value, index) {
      this.innerValue[index] = value;
      this.$emit("change", this.innerValue);
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.date =
          val && val.length
            ? [val[0] && new Date(val[0]), val[1] && new Date(val[1])]
            : this.default || [undefined, undefined];
        this.innerValue = this.date;
      }
    }
  }
};
</script>
