export default {
  path: "/dashboard/eventSource",
  i18nPath: "pages.eventSources",
  editable: false,
  addable: false,
  schema: {
    title: {
      type: String,
      required: true,
      filterOperator: "lk"
    },
    start: {
      type: Date,
      showInJalaali: true,
      format: "jYYYY/jMM/jDD"
    },
    end: {
      type: Date,
      showInJalaali: true,
      format: "jYYYY/jMM/jDD"
    },
    color: {
      type: String,
      subType: "color"
    },
    eventGroup: {
      type: String,
      ref: "eventGroup",
      multiple: false,
      itemValue: "_id",
      itemText: "name",
      descriptionText: "description",
      searchField: ["name"],
      minCharSearch: 2,
      joinChar: " | ",
      smallChips: true
    },
    duration: {
      type: Number,
      required: true,
      default: 1,
      min: 1,
      persianNumber: true
    },
    calendarType: {
      type: String,
      enum: ["Gregorian", "Jalaali"],
      i18nPath: "pages.calendar.calendarType",
      required: true,
      default: "Jalaali"
    },
    repetitiveType: {
      type: String,
      enum: ["none", "daily", "weekly", "monthly", "annual"],
      i18nPath: "pages.calendar.repetitiveType",
      required: true,
      default: "none"
    },
    repeatPeriod: {
      type: Number,
      min: 1,
      persianNumber: true
    },
    score: {
      type: Number,
      min: -10,
      max: 10,
      required: true,
      persianNumber: true
    },
    area: {
      type: String,
      enum: ["local", "region", "province", "country", "global"],
      i18nPath: "pages.calendar.area"
    },
    actors: {
      type: Array,
      ref: "actor",
      multiple: true,
      hideInBrowse: true,
      itemValue: "_id",
      itemText: "name",
      descriptionText: "description",
      searchField: ["name"],
      joinChar: " | ",
      minCharSearch: 2,
      smallChips: true
    },
    scopes: {
      type: Array,
      ref: "scope",
      multiple: true,
      hideInBrowse: true,
      itemValue: "_id",
      itemText: "name",
      descriptionText: "description",
      searchField: ["name"],
      joinChar: " | ",
      minCharSearch: 2,
      smallChips: true
    },
    isMainSubject: {
      type: Boolean,
      default: true,
      icons: ["mdi-check-circle", "mdi-close-circle"]
    },
    description: {
      type: String,
      multiLine: true,
      filterOperator: "lk"
    },
    createAt: {
      type: Date,
      showInJalaali: true,
      hide: true
    },
    lastUpdate: {
      type: Date,
      showInJalaali: true,
      hide: true
    },
    active: {
      type: Boolean,
      required: true,
      default: true,
      order: 1,
      name: "status",
      icons: ["mdi-check-circle", "mdi-close-circle"]
    },
    approved: {
      type: Boolean,
      required: true,
      default: true,
      order: 1,
      name: "status",
      icons: ["mdi-check-circle", "mdi-close-circle"]
    }
  }
};
