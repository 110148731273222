import fa from "@/i18n/fa";
export default {
  path: "/dashboard/eventGroup",
  i18nPath: "pages.eventGroups",
  schema: {
    name: {
      type: String,
      required: true,
      filterOperator: "lk"
    },
    description: {
      type: String,
      inputGrid: {
        cols: 12,
        md: 12
      },
      multiLine: true,
      filterOperator: "lk"
    },
    createAt: {
      type: Date,
      editable: false,
      addable: false,
      showInJalaali: true
    },
    lastUpdate: {
      type: Date,
      editable: false,
      addable: false,
      showInJalaali: true
    },
    active: {
      type: Boolean,
      required: true,
      default: true,
      order: 1,
      name: "status",
      icons: ["mdi-check-circle", "mdi-close-circle"]
    },
    actors: {
      type: Array,
      ref: "actor",
      multiple: true,
      hideInBrowse: true,
      itemValue: "_id",
      itemText: "name",
      descriptionText: "description",
      searchField: ["name"],
      joinChar: " | ",
      minCharSearch: 2,
      smallChips: true,
      inputGrid: {
        cols: 12,
        md: 12
      }
    },
    scopes: {
      type: Array,
      ref: "scope",
      multiple: true,
      hideInBrowse: true,
      itemValue: "_id",
      itemText: "name",
      descriptionText: "description",
      searchField: ["name"],
      joinChar: " | ",
      minCharSearch: 2,
      smallChips: true,
      inputGrid: {
        cols: 12,
        md: 12
      }
    },
    users: {
      type: Array,
      ref: "user",
      multiple: true,
      itemValue: "_id",
      hideInBrowse: true,
      itemText: item => `${item.firstName} ${item.lastName}`,
      descriptionText: item => fa.roles[item.roles],
      searchField: ["firstName", "lastName"],
      joinChar: " | ",
      minCharSearch: 2,
      smallChips: true,
      inputGrid: {
        cols: 12,
        md: 12
      }
    }
  }
};
