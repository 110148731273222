<template>
  <v-text-field
    v-model="color"
    outlined
    :label="label"
    :disabled="fieldModel.disabled"
    :hide-details="fieldModel.hideDetails || false"
    :required="fieldModel.required"
    :dense="fieldModel.dense || true"
  >
    <template v-slot:append>
      <v-menu
        v-model="menu"
        top
        nudge-bottom="105"
        nudge-left="16"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on }">
          <div :style="swatchStyle" v-on="on" />
        </template>
        <v-card>
          <v-card-text class="pa-0">
            <v-color-picker show-swatches v-model="color" flat />
          </v-card-text>
        </v-card>
      </v-menu>
    </template>
  </v-text-field>
</template>

<script>
export default {
  props: ["value", "fieldModel", "label"],
  data() {
    return {
      color: "#1976D2",
      menu: false
    };
  },
  computed: {
    swatchStyle() {
      const { color, menu } = this;
      return {
        backgroundColor: color,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: menu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
        marginTop: "-3px"
      };
    }
  },
  watch: {
    color(val) {
      this.$emit("change", val);
      this.$emit("input", val);
    },
    value: {
      immediate: true,
      handler(val) {
        this.color = val || "#1976D2";
      }
    }
  }
};
</script>
