<template>
  <v-checkbox
    v-if="fieldModel.type === Boolean"
    :label="label"
    class="my-auto"
    :indeterminate="innerValue === undefined"
    :checked="innerValue"
    :input-value="innerValue"
    @change="booleanChange"
    :hide-details="fieldModel.hideDetails || false"
    :required="fieldModel.required"
    :dense="fieldModel.dense || true"
  />
  <refField
    v-else-if="fieldModel.ref"
    v-model="innerValue"
    :fieldModel="fieldModel"
    :label="label"
    @change="onChange"
  />
  <date
    v-else-if="fieldModel.type === Date"
    v-model="innerValue"
    :fieldModel="fieldModel"
    :label="label"
    @change="changeDate"
  />
  <v-select
    v-else-if="fieldModel.enum"
    :items="items"
    v-model="innerValue"
    :label="label"
    @change="selectChange"
    :hide-details="fieldModel.hideDetails || false"
    :required="fieldModel.required"
    :dense="fieldModel.dense || true"
    outlined
  />
  <color
    v-else-if="fieldModel.subType && fieldModel.subType === 'color'"
    v-model="innerValue"
    :label="label"
    @change="onChange"
  />
  <v-textarea
    v-else-if="fieldModel.type === String && fieldModel.multiLine"
    :label="label"
    v-model="innerValue"
    :disabled="fieldModel.disabled"
    :hide-details="fieldModel.hideDetails || false"
    :required="fieldModel.required"
    @change="onChange"
    outlined
    :dense="fieldModel.dense || true"
  />
  <number
    v-else-if="fieldModel.type === Number"
    v-model="innerValue"
    :fieldModel="fieldModel"
    :label="label"
    @change="onChange"
  />
  <v-text-field
    v-else
    v-model="innerValue"
    outlined
    type="text"
    :label="label"
    :disabled="fieldModel.disabled"
    :step="fieldModel.isDecimal == 'decimal' ? 0.01 : 1"
    :mask="
      ['date', 'time', 'datetime'].includes(fieldModel.fieldType)
        ? masks[fieldModel.fieldType]
        : undefined
    "
    :return-masked-value="
      ['date', 'time', 'datetime'].includes(fieldModel.fieldType) ? true : false
    "
    min="0"
    :hide-details="fieldModel.hideDetails || false"
    :required="fieldModel.required"
    @change="onChange"
    :dense="fieldModel.dense || true"
  />
</template>

<script>
import date from "./date";
import refField from "./refField";
import color from "../inputFields/color";

import moment from "moment";
import Number from "./number.vue";
export default {
  props: ["value", "fieldModel", "label"],
  data() {
    return {
      innerValue: undefined,
      masks: {
        date: "####-##-##",
        time: "##:##",
        datetime: "####-##-## ##:##:##"
      }
    };
  },
  computed: {
    min() {
      return (
        this.fieldModel.type === Number && [
          this.fieldModel.min,
          Math.max(
            this.innerValue ? this.innerValue[0] : -Infinity,
            this.fieldModel.min
          )
        ]
      );
    },
    max() {
      return (
        this.fieldModel.type === Number && [
          Math.min(
            this.innerValue ? this.innerValue[1] : Infinity,
            this.fieldModel.max
          ),
          this.fieldModel.max
        ]
      );
    },
    items() {
      return this.fieldModel.i18nPath
        ? ["all", ...this.fieldModel.enum].map(value => ({
            text: this.$vuetify.lang.t(
              `$vuetify.${this.fieldModel.i18nPath}.${value}`
            ),
            value
          }))
        : ["all", ...this.fieldModel.enum];
    }
  },
  methods: {
    onChange() {
      this.$emit("input", this.innerValue);
    },
    booleanChange() {
      this.$emit(
        "input",
        this.innerValue === undefined
          ? true
          : this.innerValue === true
          ? false
          : undefined
      );
    },
    changeDate(value) {
      this.$emit("input", [
        value[0] && moment(value[0])._d.getTime(),
        value[1] && moment(value[1])._d.getTime()
      ]);
    },
    selectChange() {
      this.$emit(
        "input",
        this.innerValue === "all" ? undefined : this.innerValue
      );
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.innerValue = this.fieldModel.enum
          ? val === undefined
            ? "all"
            : val
          : val;
      }
    }
  },
  components: {
    date,
    refField,
    color,
    Number
  }
};
</script>
