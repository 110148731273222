import api from "./api";

export const breadServicesWithCompleteURL = {
  browse: async (url, data, page, perPage) =>
    api.post(`${url}/${(page - 1) * perPage}/${perPage}`, data),
  read: async (url, id) => api.get(`${url}/${id}`),
  edit: async (url, id, data) => api.put(`${url}/${id}`, data),
  add: async (url, data) => api.post(`${url}`, data),
  delete: async (url, id) => api.delete(`${url}/${id}`)
};

const BreadServices = function(path) {
  this.browse = async (data, page, perPage) =>
    breadServicesWithCompleteURL.browse(`${path}/browse`, data, page, perPage);
  this.read = async id => breadServicesWithCompleteURL.read(path, id);
  this.edit = async (id, data) =>
    breadServicesWithCompleteURL.edit(path, id, data);
  this.add = async data => breadServicesWithCompleteURL.add(path, data);
  this.delete = async id => breadServicesWithCompleteURL.delete(path, id);
};

export default BreadServices;
