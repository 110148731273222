<template>
  <v-autocomplete
    v-model="model"
    :items="items"
    :search-input.sync="search"
    :item-value="fieldModel.itemValue"
    :multiple="fieldModel.multiple"
    :chips="!fieldModel.noChip && fieldModel.multiple"
    :deletable-chips="!fieldModel.noChip && fieldModel.multiple"
    :label="label"
    :dense="fieldModel.dense || true"
    :filter="fieldModel.filter || filter"
    auto-select-first
    :clearable="fieldModel.required"
    :required="fieldModel.multiple && fieldModel.required"
    :rules="
      fieldModel.required
        ? $globalRules.required($vuetify.lang.t(`$vuetify.validation.required`))
        : []
    "
    outlined
    @input="onInput"
    @change="onChange"
  >
    <template v-slot:append>
      <v-progress-circular v-if="isLoading" indeterminate width="2" size="20" />
    </template>
    <template #selection="data">
      <v-chip
        v-if="!fieldModel.noChip"
        v-bind="data.attrs"
        :input-value="data.selected"
        :small="fieldModel.smallChips || fieldModel.dense || true"
        class="mt-1 ml-1"
      >
        {{ getTitle(data.item) }}
      </v-chip>
      <span v-else>
        {{ getTitle(data.item) }}
      </span>
    </template>
    <template #item="data">
      <template v-if="typeof data.item !== 'object'">
        <v-list-item-content v-text="data.item"></v-list-item-content>
      </template>
      <template v-else>
        <v-list-item-content>
          <v-list-item-title>{{ getTitle(data.item) }}</v-list-item-title>
          <v-list-item-subtitle>{{
            getSubTitle(data.item)
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </template>
    <template #append-item>
      <v-list-item disabled v-if="total > 20">
        {{ $vuetify.lang.t(`$vuetify.dataTable.refFieldMore`) }}
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
import Bread from "@/services/bread";
import models from "@/models";
export default {
  props: ["value", "fieldModel", "label", "idSuffix", "min", "max"],
  data() {
    return {
      descriptionLimit: 60,
      items: [],
      total: 0,
      isLoading: false,
      model: null,
      search: null,
      page: 1
    };
  },
  computed: {
    fields() {
      if (!this.model) return [];

      return Object.keys(this.model).map(key => {
        return {
          key,
          value: this.model[key] || "n/a"
        };
      });
    }
  },
  created() {
    this.bread = new Bread(models[this.fieldModel.ref].path);
    this.getItems("");
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.model = val;
      }
    },
    search(val) {
      if (!val || val.length < this.fieldModel.minCharSearch) return;
      this.getItems(val);
    }
  },
  methods: {
    onInput(val) {
      this.model = val;
      this.$emit("input", this.model);
    },
    onChange(val) {
      this.$emit("change", val);
    },
    remove(item) {
      const index = this.model.indexOf(item);
      if (index >= 0) this.model.splice(index, 1);
    },
    getValue(item) {
      if (typeof this.fieldModel.itemValue === "string") {
        return item[this.fieldModel.itemValue];
      } else {
        return this.fieldModel.itemValue(item);
      }
    },
    getTitle(item) {
      if (typeof this.fieldModel.itemText === "string") {
        return item[this.fieldModel.itemText];
      } else {
        return this.fieldModel.itemText(item);
      }
    },
    getSubTitle(item) {
      if (typeof this.fieldModel.descriptionText === "string") {
        return item[this.fieldModel.descriptionText];
      } else {
        return this.fieldModel.descriptionText(item);
      }
    },
    filter(item, queryText) {
      return this.getTitle(item).indexOf(queryText) > -1;
    },
    getItems(val) {
      this.isLoading = true;
      const query = this.fieldModel.searchField.map(field => ({
        field,
        operator: "lk",
        value: [val]
      }));
      this.bread
        .browse({ query }, this.page, 20)
        .then(res => {
          this.items = [...new Set(...this.items, res.data.items)];
          this.total = res.data.total;
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>
