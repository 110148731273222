export default {
  path: "/dashboard/scope",
  i18nPath: "pages.scopes",
  schema: {
    name: {
      type: String,
      required: true,
      filterOperator: "lk"
    },
    description: {
      type: String,
      inputGrid: {
        cols: 12,
        md: 12
      },
      multiLine: true,
      filterOperator: "lk"
    },
    createAt: {
      type: Date,
      editable: false,
      addable: false,
      showInJalaali: true
    },
    lastUpdate: {
      type: Date,
      editable: false,
      addable: false,
      showInJalaali: true
    },
    active: {
      type: Boolean,
      required: true,
      default: true,
      order: 1,
      name: "status",
      icons: ["mdi-check-circle", "mdi-close-circle"]
    },
    eventGroups: {
      type: Array,
      ref: "eventGroup",
      multiple: true,
      itemValue: "_id",
      itemText: "name",
      descriptionText: "description",
      searchField: ["name"],
      joinChar: " | ",
      minCharSearch: 2,
      smallChips: true,
      inputGrid: {
        cols: 12,
        md: 12
      }
    }
  }
};
