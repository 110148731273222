<template>
  <v-row>
    <v-col>
      <inputNumber
        v-model="range[0]"
        :label="`${label} (${$vuetify.lang.t(`$vuetify.date.from`)})`"
        :fieldModel="{
          ...fieldModel,
          required: false,
          min: min[0],
          max: max[0]
        }"
        @input="v => onInput(v, 0)"
        @change="v => onInput(v, 0)"
        idSuffix="0"
      />
    </v-col>
    <v-col>
      <inputNumber
        v-model="range[1]"
        :label="`${label} (${$vuetify.lang.t(`$vuetify.date.to`)})`"
        :fieldModel="{
          ...fieldModel,
          required: false,
          min: min[1],
          max: max[1]
        }"
        @input="v => onInput(v, 1)"
        @change="v => onInput(v, 1)"
        idSuffix="1"
      />
    </v-col>
  </v-row>
</template>

<script>
import inputNumber from "../inputFields";
export default {
  props: ["value", "fieldModel", "label", "default"],
  data() {
    return {
      range: [undefined, undefined]
    };
  },
  computed: {
    min() {
      return (
        this.fieldModel.type === Number && [
          this.fieldModel.min,
          Math.max(this.range ? this.range[0] : -Infinity, this.fieldModel.min)
        ]
      );
    },
    max() {
      return (
        this.fieldModel.type === Number && [
          Math.min(this.range ? this.range[1] : Infinity, this.fieldModel.max),
          this.fieldModel.max
        ]
      );
    }
  },
  components: {
    inputNumber
  },
  methods: {
    onInput(value, index) {
      this.range[index] = +value;
      this.$emit("input", [...this.range]);
      this.$emit("change", [...this.range]);
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.range = val || this.default || [undefined, undefined];
      }
    }
  }
};
</script>
