export const englishNumber = string =>
  string !== undefined &&
  String(string)
    .replace(/۰/g, "0")
    .replace(/۱/g, "1")
    .replace(/۲/g, "2")
    .replace(/۳/g, "3")
    .replace(/۴/g, "4")
    .replace(/۵/g, "5")
    .replace(/۶/g, "6")
    .replace(/۷/g, "7")
    .replace(/۸/g, "8")
    .replace(/۹/g, "9")
    .replace(/٠/g, "0")
    .replace(/١/g, "1")
    .replace(/٢/g, "2")
    .replace(/٣/g, "3")
    .replace(/٤/g, "4")
    .replace(/٥/g, "5")
    .replace(/٦/g, "6")
    .replace(/٧/g, "7")
    .replace(/٨/g, "8")
    .replace(/٩/g, "9");

export const downloadFile = (url, fileName) => {
  const a = document.createElement("a");
  a.setAttribute("href", url);
  a.setAttribute("download", fileName);

  a.style.display = "none";
  document.body.appendChild(a);

  a.click();

  document.body.removeChild(a);
};

export function hexToRgb(hex) {
  const _hex =
    hex.replace("#", "").length === 3
      ? hex
          .split("")
          .reduce((acc, i) => (i === "#" ? "#" : `${acc}${i}${i}`), "")
      : hex;
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(_hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;
}

export function calcTextColor(color) {
  try {
    const { r, g, b } = hexToRgb(color);
    const gray = Math.floor(0.2126 * r + 0.7152 * g + 0.0722 * b);
    return gray < 100 ? "#FFF" : "#000";
  } catch (e) {
    return "#FFF";
  }
}

export function getValue(input, item) {
  const _item = { ...item };
  return typeof input === "function" ? input(_item) : input;
}

export function getValueOfObj(obj, item, skips = ["type"]) {
  const _item = { ...item };
  return Reflect.ownKeys(obj).reduce(
    (prev, key) => ({
      ...prev,
      [key]:
        skips.includes(key) || typeof obj[key] !== "function"
          ? obj[key]
          : obj[key](_item)
    }),
    {}
  );
}

export function groupBy(array, key) {
  return array.reduce(function(obj, item) {
    (obj[typeof key === "function" ? key(item) : item[key]] =
      obj[typeof key === "function" ? key(item) : item[key]] || []).push(item);
    return obj;
  }, {});
}
